import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";
import { Video } from "../../app/modules/videos/core/_models";
import { LiveStream } from "../../app/modules/liveStreams/core/_models";

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export function formatDate(timeIsoString: string) {
  return format(
    utcToZonedTime(
      timeIsoString,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    ),
    "Pp"
    );
}

export function formatDuration(duration: number): string {
  const hours = "" + Math.floor(duration / 3600);
  const remaining = duration % 3600;
  const minutes = "" + (Math.floor(remaining / 60));
  const seconds = "" + (remaining % 60).toFixed(2);

  return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}:${seconds.padStart(5, "0")}`;
}

export function numberToArray(n: number, value: string): string[] {
  if (n <= 0) {
    return [];
  }

  return Array.from(
    { length: n },
    () => value
  );
}

export function getPublicPlaybackIDs(data: Video | LiveStream) {
  return data.playbackIds
    .filter((playbackID) => playbackID.playbackPolicy === "public");
}

export function getPlaybackURL(id: string): string {
  return `${process.env.REACT_APP_STREAM_URL}/${id}.m3u8`;
}

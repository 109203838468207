import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import YupPassword from "yup-password"
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { ServerError, getError } from '../../../../_metronic/helpers'
import { confirmSignup, validateInviteId } from '../core/_requests'

YupPassword(Yup);

const initialValues = {
  firstName: '',
  lastName: '',
  teamName: '',
  password: '',
  confirmPassword: '',
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('A first name is required'),
  lastName: Yup.string()
    .required('A last name is required'),
  teamName: Yup.string()
    .required("A team name is required"),
  password: Yup.string()
    .password()
    .minLowercase(1, "At least one lowercase character is required")
    .minUppercase(1, "At least one uppercase character is required")
    .minSymbols(0)
    .minNumbers(0)
    .min(8, 'At least 8 characters are required')
    .required('A password is required'),
  confirmPassword: Yup.string()
    .required('Please re-enter your password')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Passwords do not match")
    }),
})

export function ConfirmSignUp() {
  const navigate = useNavigate();
  const { inviteId } = useParams();
  const [showForm, setShowForm] = useState(false);

  const { data } = useQuery(
    "validate-invite-id",
    () => validateInviteId({inviteId: inviteId as string}),
    {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      retry: false,
      onError: (errors: ServerError) => {
        navigate("/login", {
          state: getError(errors)
        });
      }
    }
  );

  useEffect(() => {
    if (data) {
      if (data.data?.status === "valid") {
        setShowForm(true);
      } else {
        navigate("/login", {
          state: "Invalid invite ID"
        });
      }
    }
  }, [data, navigate]);

  const confirmSignupMutation = useMutation(
    confirmSignup
  );

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;

      if (!siteKey || siteKey === "") {
        setStatus("An unexpected error occured");
        return;
      }

      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute(siteKey, {
            action: "submit",
          });

          const { confirmPassword, ...rest } = values;

          confirmSignupMutation.mutate({ ...rest, inviteId: inviteId as string, token }, {
            onSuccess: () => {
              // logs in user
              navigate("/dashboard");
            },
            onError: (errors: ServerError) => {
              setStatus(getError(errors))
              setSubmitting(false)
              setLoading(false)
            }
          });
        } catch (error) {
          setStatus("An unexpected error occured")
          setSubmitting(false)
          setLoading(false)
        }
      });
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  if (!showForm) {
    return null;
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
      autoComplete="false"
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Finish signing up</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
        <div className='col-xl-6'>
          <label className='form-label fw-bolder text-dark fs-6'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Last name</label>
            <input
              placeholder='Last name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.lastName && formik.errors.lastName,
                },
                {
                  'is-valid': formik.touched.lastName && !formik.errors.lastName,
                }
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Team name */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Team name</label>
        <input
          placeholder='Team name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('teamName')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.teamName && formik.errors.teamName },
            {
              'is-valid': formik.touched.teamName && !formik.errors.teamName,
            }
          )}
        />
        {formik.touched.teamName && formik.errors.teamName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.teamName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of upper and lowercase letters
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}

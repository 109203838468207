import { merge } from 'lodash'
import { createContext, FC, useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { initialQueryRequest, QueryRequestContextProps, QueryState } from './models'

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC = ({children}) => {
  const [searchParams] = useSearchParams();
  const queryPage = searchParams.get("page");
  const queryLimit = searchParams.get("limit");

  if (queryPage) {
    initialQueryRequest.state.page = parseInt(queryPage);
  }

  if (queryLimit) {
    initialQueryRequest.state.limit = parseInt(queryLimit);
  }

  const [state, setState] = useState<QueryState>(initialQueryRequest.state)

  const updateState = (updates: Partial<QueryState>) => {
    setState((prev) => {
      const updatedState = {};
      return merge(updatedState, prev, updates)
    })
  }

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }

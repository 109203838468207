import clsx from "clsx";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useMutation } from "react-query";
import { Navigate, useNavigate, useParams } from "react-router";
import * as yup from "yup";
import YupPassword from "yup-password";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { ServerError, getError } from "../../../../_metronic/helpers";
import { ResetPasswordDto } from "../core/_models";
import { resetPassword } from "../core/_requests";

YupPassword(yup);

const validationSchema: yup.SchemaOf<ResetPasswordDto> = yup.object().shape({
  password: yup.string()
    .password()
    .minLowercase(1, "At least one lowercase character is required")
    .minUppercase(1, "At least one uppercase character is required")
    .minSymbols(0)
    .minNumbers(0)
    .min(8, 'At least 8 characters are required')
    .required('A password is required'),
  confirmPassword: yup.string()
    .required('Please re-enter your password')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], "Passwords do not match")
    }),
  token: yup.string().required()
})

const ForgotPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const resetPasswordMutation = useMutation(
    resetPassword,
    {
      onSuccess: () => {
        navigate("/login")
      }
    }
  )

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  if (!token) {
    return <Navigate to="/login" />
  }

  const initialValues: ResetPasswordDto = {
    password: '',
    confirmPassword: '',
    token: token
  }

  return (<>
    <div className='text-center mb-10'>
      <h1 className='text-dark mb-3'>Reset your password</h1>
    </div>

    <Formik<ResetPasswordDto>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        resetPasswordMutation.mutate(values, {
          onError: (errors: ServerError) => {
            setStatus(getError(errors))
            setSubmitting(false)
          }
        })
      }}
    >
      {(props) => (
        <Form>
          {props.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{props.status}</div>
            </div>
          )}

          <div className='fv-row mb-10'>
            <div className='mb-10 fv-row' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>New password</label>
                <div className='position-relative mb-3'>
                  <Field
                    name='password'
                    type='password'
                    placeholder='New password'
                    autoFocus={true}
                    autoComplete='off'
                    required
                    value={props.values.password}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      { 'is-invalid': props.touched.password && props.errors.password },
                      {
                        'is-valid': props.touched.password && !props.errors.password,
                      }
                    )}
                  />
                  <div className='text-danger'>
                    <ErrorMessage name='password' />
                  </div>
                </div>
                {/* begin::Meter */}
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
              </div>
              <div className='text-muted'>
                Use 8 or more characters with a mix of upper and lowercase letters
              </div>
            </div>
          </div>

          <div className='fv-row mb-10'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm password</label>
            <Field
              name='confirmPassword'
              type='password'
              placeholder='Confirm password'
              autoFocus={true}
              autoComplete='off'
              required
              value={props.values.confirmPassword}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': props.touched.confirmPassword && props.errors.confirmPassword },
                {
                  'is-valid': props.touched.confirmPassword && !props.errors.confirmPassword,
                }
              )}
            />
            <div className='text-danger'>
              <ErrorMessage name='confirmPassword' />
            </div>
          </div>
          <Button type="submit" disabled={props.isSubmitting}>
            {props.isSubmitting ? "Please wait..." : "Update password"}
          </Button>
        </Form>
      )}
    </Formik>
  </>)
}

export { ForgotPassword };

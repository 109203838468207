/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { App } from '../App'
import { AuthLayout, Logout, useAuth } from '../modules/auth'
import { RequestPasswordReset } from '../modules/auth/components/RequestPasswordReset'
import { ForgotPassword } from '../modules/auth/components/ForgotPassword'
import { Login } from '../modules/auth/components/Login'
import { ConfirmSignUp } from '../modules/auth/components/ConfirmSignUp'
import { RegisterInvite } from '../modules/auth/components/RegisterInvite'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { PrivateRoutes } from './PrivateRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/live-streams' />} />
            </>
          ) : (
            <Route element={<AuthLayout />}>
              <Route path='*' element={<Navigate to="/invite" />} />
              <Route path='login' element={<Login />} />
              <Route path='invite' element={<RegisterInvite />} />
              <Route path='signup/:inviteId' element={<ConfirmSignUp />} />
              <Route path='forgot-password' element={<RequestPasswordReset />} />
              <Route path='reset-password/:token' element={<ForgotPassword />} />
            </Route>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }

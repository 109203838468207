import {MenuItem} from './MenuItem'

export function MenuInner() {
  return (
    <>
      <MenuItem title="Dashboard" to='/dashboard' />
      <MenuItem title="Live Streams" to='/live-streams' />
      <MenuItem title="Videos" to='/videos' />
    </>
  )
}

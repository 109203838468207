const QUERIES = {
  USERS_LIST: 'users-list',
  LIVE_STREAM_LIST: 'live-stream-list',
  LIVE_STREAM_INFO: 'live-stream-info',
  VIDEO_LIST: 'video-list',
  VIDEO_INFO: 'video-info',
  ACCESS_TOKEN_LIST: 'access-token-list',
  ACCESS_TOKEN_INFO: 'access-token-info',
  STRIPE_CLIENT_SECRET: 'stripe-client-secret',
  GET_BILLING_DETAILS: 'get-billing-details',
}

export {QUERIES}

import clsx from 'clsx'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Link, useLocation } from 'react-router-dom'
import * as Yup from 'yup'
import { ServerError, getError } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { login } from '../core/_requests'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('An email is required'),
  password: Yup.string()
    .min(8, 'At least 8 characters are required')
    .required('A password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const { state } = useLocation();
  const loginMutation = useMutation(login)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;

      if (!siteKey || siteKey === "") {
        saveAuth(undefined);
        setStatus("An unexpected error occured");
        setSubmitting(false);
        setLoading(false);
        return;
      }

      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute(siteKey, {
            action: "submit",
          });

          loginMutation.mutate({
            ...values,
            token,
          }, {
            onSuccess: ({ data }) => {
              saveAuth(data)
              setCurrentUser(data?.user);
            },
            onError: (errors: ServerError) => {
              saveAuth(undefined)
              setStatus(getError(errors))
              setSubmitting(false)
              setLoading(false)
            }
          });
        } catch (error) {
          saveAuth(undefined)
          setStatus("An unexpected error occured")
          setSubmitting(false)
          setLoading(false)
        }
      });
    },
  })

  // clears location state after refresh
  useEffect(() => {
    window.history.replaceState({}, document.title)
  }, []);

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {state && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{state as string}</div>
        </div>
      )}
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign in to Maxx.Video</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/invite' className='link-primary fw-bolder'>
            Get an invite
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
          autoFocus
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          placeholder='Password'
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

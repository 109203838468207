import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDebounce } from "..";
import { PaginationState } from "../crud-helper/models";
import { useQueryRequest } from "../crud-helper/QueryRequestProvider";

type Props = {
  pagination: PaginationState
}

function createNumberArray(start: number, end: number): number[] {
  const length = end - start + 1;

  return Array.from({ length }, (_, i) => i + start);
}

function ListPagination({ pagination }: Props): JSX.Element | null {
  const siblingCount = 2;
  const { updateState } = useQueryRequest();
  const { page, totalItems, totalPages, limit } = pagination;
  const totalPageNumbers = siblingCount + 4;
  const [customLimit, setLimit] = useState(limit);
  const debouncedUpdateState = useDebounce(customLimit.toString(), 120);

  useEffect(() => {
    updateState({
      limit: debouncedUpdateState ? parseInt(debouncedUpdateState) : undefined
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdateState]);

  let paginationArray: (string | number)[] = [];

  function updatePage(page: number | undefined) {
    updateState({ page });
  }

  if (totalPageNumbers >= totalPages) {
    paginationArray = createNumberArray(1, totalPages);
  }

  const leftSiblingIndex = Math.max(page - siblingCount, 1);
  const rightSiblingIndex = Math.min(
    page + siblingCount,
    totalPages
  );

  const shouldShowLeftDots = leftSiblingIndex > 2;
  const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

  const firstPageIndex = 1;
  const lastPageIndex = totalPages;

  if (!shouldShowLeftDots && shouldShowRightDots) {
    let leftItemCount = 2 * siblingCount;
    let leftRange = createNumberArray(1, leftItemCount);

    paginationArray = [...leftRange, "...", totalPages];
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    let rightItemCount = 2 * siblingCount;
    let rightRange = createNumberArray(
      totalPages - rightItemCount + 1,
      totalPages
    );

    paginationArray = [firstPageIndex, "...", ...rightRange];
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    let middleRange = createNumberArray(leftSiblingIndex, rightSiblingIndex);

    paginationArray = [firstPageIndex, "...", ...middleRange, "...", lastPageIndex];
  }

  return (
    <nav className="d-flex justify-content-between" aria-label="Page navigation example">
      <div>
        <ul className="pagination">
          {paginationArray.length > 0 && (<>
            <li className="page-item">
              <button className="page-link previous" onClick={() => updatePage(page - 1)}>
                Previous
              </button>
            </li>
            {paginationArray && paginationArray.map((item) => (
              <li
                className={clsx([
                  "page-item",
                  { "active": page === item }
                ])}
                key={item}>
                <button
                  onClick={() => {
                    if (typeof item === "number") {
                      updatePage(item)
                    }
                  }}
                  className="page-link"
                >
                  {item}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button className="page-link next" onClick={() => updatePage(page + 1)}>
                Next
              </button>
            </li>
          </>)}
        </ul>
      </div>
      {totalItems > 0 && (
        <div className="d-flex align-items-center">
          <input className="form-control form-control-lg form-control-solid me-4" style={{ width: "90px" }} type="number" value={customLimit} onChange={(e) => setLimit(parseInt(e.target.value))} />
          Displaying {limit} of {totalItems} items
        </div>
      )}
    </nav>
  );
};

export { ListPagination };

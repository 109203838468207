import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title="Dashboard"
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/live-streams'
        title='Live Streams'
        fontIcon='bi-record-fill'
      />
      <AsideMenuItem
        to='/videos'
        title='Videos'
        fontIcon='bi-collection-play'
      />

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>
      <AsideMenuItem
        to='account/settings'
        title='Account Settings'
        fontIcon='bi-gear-fill'
      />
      <AsideMenuItem
        to='logout'
        title='Sign Out'
        fontIcon='bi-box-arrow-left'
      />
    </>
  )
}

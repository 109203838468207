import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import { object, SchemaOf, string } from "yup";
import { ServerError, getError } from "../../../../_metronic/helpers";
import { createRegisterationInvite } from "../core/_requests";

type RegisterInviteDto = {
  email: string
}

const initialValues = {
  email: ""
};

const schema: SchemaOf<RegisterInviteDto> = object().shape({
  email: string().email("Invalid email address").required("An email address is required")
});

const RegisterInvite = () => {
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const registerInviteMutation = useMutation(createRegisterationInvite);

  return (
    <Formik<RegisterInviteDto>
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={(values, formikHelpers) => {
        const siteKey = process.env.REACT_APP_RECAPTCHA_KEY;

        if (!siteKey || siteKey === "") {
          setErrors("An unexpected error occured");
          formikHelpers.setSubmitting(false);
          return;
        }

        grecaptcha.ready(async () => {
          try {
            const token = await grecaptcha.execute(siteKey, {
              action: "submit",
            });

            registerInviteMutation.mutate(
              { ...values, token },
              {
                onSuccess: ({ data }) => {
                  formikHelpers.setStatus(data.data?.success.message);
                  formikHelpers.setSubmitting(false);
                  setErrors(undefined);
                },
                onError: (errors: ServerError) => {
                  formikHelpers.setSubmitting(false);
                  formikHelpers.setStatus(null);
                  setErrors(getError(errors));
                }
              })
          } catch (error) {
            formikHelpers.setSubmitting(false);
            setErrors("An unexpected error occured");
            console.error(error);
          }
        });
      }}
    >
      {(props) => (<>
        <Form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
          <div className='mb-10 text-center'>
            <h1 className='text-dark mb-3'>Get an Invite</h1>

            <div className='text-gray-400 fw-bold fs-4'>
              or
              <Link to='/login' className='link-primary fw-bolder' style={{ marginLeft: '5px' }}>
                log in to your account
              </Link>
            </div>
          </div>

          {errors && (
            <div className='mb-lg-10 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {errors}
              </div>
            </div>
          )}

          {props.status && (
            <div className='mb-lg-10 alert alert-success'>
              <div className='alert-text font-weight-bold'>
                {props.status}
              </div>
            </div>
          )}

          <div className='fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Email</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Email address'
              ></i>
            </label>

            <Field
              type='email'
              className='form-control form-control-lg form-control-solid'
              name='email'
              placeholder='Email address'
              value={props.values.email}
            />
            <div className='text-danger'>
              <ErrorMessage name='email' />
            </div>
          </div>

          <Button className="w-100" type="submit" disabled={props.isSubmitting}>
            Get invite
          </Button>
        </Form>
      </>)}
    </Formik>
  );
}

export { RegisterInvite };

export function parseCommaList(value: string): string[] {
  return value
    .trim()
    .split(",")
    .map((v) => v.trim())
    .filter((v) => v !== "");
}

export type ServerError = {
  response: {
    data: {
      error: {
        type: string
        messages?: string[]
        message?: string
      }
    }
  }
}

export function getError(errors: ServerError) {
  if (errors.response.data.error.messages) {
    return errors.response.data.error.messages[0];
  }

  return errors.response.data.error.message;
}
